import "slick-carousel/slick/slick.css";

import { useCallback, useState } from "react";

import L1 from "../../assets/images/L1.jpg";
import L2 from "../../assets/images/L2.jpg";
import L3 from "../../assets/images/L3.jpg";
import L4 from "../../assets/images/L4.jpg";
import L5 from "../../assets/images/L5.jpg";
import L6 from "../../assets/images/L6.jpg";
import L7 from "../../assets/images/L7.jpg";
import L8 from "../../assets/images/L8.jpg";
import L9 from "../../assets/images/Doc1.jpg";
import L10 from "../../assets/images/Doc2.JPG";
import SectionBg from "../../assets/images/secBg4.png";
import { SectionTitleLight } from "../SectionTitles";

const Testimonials = (props) => {
   
   const [currentSlider, setCurrentSlider] = useState(0);
   // The slider images array
   const sliderImages = [L9, L10, L1, L2, L3, L4, L5, L6, L7, L8];
   const prevSlider = () => {
     setCurrentSlider((currentSlider) =>
       currentSlider === 0 ? sliderImages.length - 1 : currentSlider - 1
     );
   };
   const nextSlider = useCallback(() => {
     setCurrentSlider((currentSlider) =>
       currentSlider === sliderImages.length - 1 ? 0 : currentSlider + 1
     );
   }, [sliderImages.length]);

   // if you don't want to change the slider automatically then you can just remove the useEffect
  //  useEffect(() => {
  //    const intervalId = setInterval(() => {
  //      nextSlider();
  //    }, 3000);
  //    return () => clearInterval(intervalId);
  //  }, [nextSlider, currentSlider]);
  

  return (
    <section className="testiWrap pt-[10rem] md:pt-[22rem] lg:pt-[26rem] -mt-24 sm:mt[-12rem] md:mt-[-15rem] lg:mt-[-17.625rem] pb-[4.5rem] sm:pb-[5.5rem] md:pb-[6.5rem] lg:pb-[8.75rem] relative w-full">
      <span className="bg-accent absolute right-0 bottom-[-.75rem] h-6 w-1/2"></span>
      <div
        className="fixedBg bg-center bg-no-repeat before:absolute before:z-[1] before:inset-0 before:opacity-90 before:bg-dark bg-dark gradBg1 bg-blend-color-dodge xl:rounded-tl-[20rem] lg:rounded-tl-[18rem] md:rounded-tl-[15rem] sm:rounded-tl-[10rem] rounded-tl-0 overflow-hidden"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <SectionTitleLight
          title=""
          titleInner="Recognition on My Crafting of Passion"
          desc=""
        ></SectionTitleLight>
        <div className="lg:w-[70%] mx-auto py-4">
          <div className="relative mx-auto w-fit">
            {/* arrow left */}
            <button
              onClick={prevSlider}
              className="absolute -left-6 top-1/2 flex h-6 w-6 items-center justify-center md:h-8 md:w-8"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="icon h-4 w-4 md:h-6 md:w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
              >
                <g strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"></path>
                </g>
              </svg>
            </button>
            {/* arrow right */}
            <button
              onClick={nextSlider}
              className="absolute -right-6 top-1/2 flex h-6 w-6 items-center justify-center md:h-8 md:w-8"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="icon h-4 w-4 md:h-6 md:w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                transform="rotate(180)"
              >
                <g strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"></path>
                </g>
              </svg>
            </button>
            <div className="w-full max-w-72 overflow-hidden">
              {/* slider container */}
              <div
                className="flex transform-gpu duration-500 ease-linear w-[350px] h-[480px] md:w-[400px] md:h-[580px]"
                style={{ transform: `translateX(-${currentSlider * 100}%)` }}
              >
                {/* sliders */}
                {sliderImages.map((slide, inx) => (
                  <img
                    width={500}
                    height={500}
                    key={inx}
                    src={slide}
                    className="mx-[2.5%] h-full min-w-[95%] rounded-2xl border-0  object-cover"
                    alt={`Slider - ${inx + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials Wrap */}
    </section>
  );
};

export default Testimonials;
