import React, { useState } from 'react';

import P1 from "../../assets/projects/po1.jpg";
import P10 from "../../assets/projects/po10.jpg";
import P11 from "../../assets/projects/po11.jpg";
import P12 from "../../assets/projects/po12.jpg";
import P13 from "../../assets/projects/po13.jpg";
import P14 from "../../assets/projects/po14.jpg";
import P15 from "../../assets/projects/po15.jpg";
import P16 from "../../assets/projects/po16.jpg";
import P17 from "../../assets/projects/po17.jpg";
import P18 from "../../assets/projects/po18.jpg";
import P19 from "../../assets/projects/po19.jpg";
import P2 from "../../assets/projects/po2.jpg";
import P20 from "../../assets/projects/po20.jpg";
import P21 from "../../assets/projects/po21.jpg";
import P22 from "../../assets/projects/po22.jpg";
import P23 from "../../assets/projects/po23.jpg";
import P24 from "../../assets/projects/po24.jpg";
import P25 from "../../assets/projects/Rp1.jpg";
import P26 from "../../assets/projects/Rp2.jpg";
import P27 from "../../assets/projects/Rp3.jpg";
import P28 from "../../assets/projects/Rp4.jpg";
import P3 from "../../assets/projects/po3.jpg";
import P4 from "../../assets/projects/po4.jpg";
import P5 from "../../assets/projects/po5.jpg";
import P6 from "../../assets/projects/po6.jpg";
import P7 from "../../assets/projects/po7.jpg";
import P8 from "../../assets/projects/po8.jpg";
import P9 from "../../assets/projects/po9.jpg";

export const CarouselMain = () => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const sliders = [
    {
      img: P25,
    },
    {
      img: P26,
    },
    {
      img: P27,
    },
    {
      img: P28,
    },
    {
      img: P1,
    },
    {
      img: P2,
    },
    {
      img: P3,
    },
    {
      img: P4,
    },
    {
      img: P5,
    },
    {
      img: P6,
    },
    {
      img: P7,
    },
    {
      img: P8,
    },
    {
      img: P9,
    },
    {
      img: P10,
    },
    {
      img: P11,
    },
    {
      img: P12,
    },
    {
      img: P13,
    },
    {
      img: P14,
    },
    {
      img: P15,
    },
    {
      img: P16,
    },
    {
      img: P17,
    },
    {
      img: P18,
    },
    {
      img: P19,
    },
    {
      img: P20,
    },
    {
      img: P21,
    },
    {
      img: P22,
    },
    {
      img: P23,
    },
    {
      img: P24,
    },
  ];
  const nextSlider = () =>
    setCurrentSlider((currentSlider) =>
      currentSlider === sliders.length - 1 ? 0 : currentSlider + 1
    );
  return (
    <div className="sm:w-3/4 h-[440px] md:h-[670px] flex items-center relative overflow-hidden -mt-20 md:mt-0">
      {/* arrow */}
      <button
        onClick={nextSlider}
        className="absolute flex justify-center items-center right-2 top-1/2 bg-white rounded-full z-50 w-6 h-6 md:w-8 md:h-8 bgWhite "
      >
        <svg
          className="icon h-4 w-4 fill-black/50 md:h-6 md:w-6"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(180)"
        >
          <path d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"></path>
        </svg>
      </button>
      {/* slider container */}
      <div
        className="ease-linear duration-300 flex gap-[2%]"
        style={{ transform: `translateX(-${currentSlider * 52}%)` }}
      >
        {/* sliders */}
        {sliders.map((slide, inx) => (
          <div
            key={inx}
            className={`${
              currentSlider === inx
                ? "h-[310px] md:h-[310px] lg:h-[580px] "
                : "h-[260px] md:h-[280px] lg:h-[480px]"
            } min-w-[50%] bg-black/30 relative duration-200`}
          >
            <img src={slide.img} className="w-full h-full" alt={slide.tags} />
          </div>
        ))}
      </div>
    </div>
  );
};

const HomePosters = () => {
    return (
      <div className="max-w-7xl mx-auto h-[540px] lg:h-[670px] px-3 lg:px-10 flex flex-col lg:flex-row items-center justify-center overflow-hidden gap-5 lg:gap-10 relative">
        <CarouselMain />
      </div>
    );
};

export default HomePosters;