import PostImg1 from "../assets/images/resources/postImg2-1.png";
import PostImg2 from "../assets/images/resources/postImg2-2.png";
import PostImg3 from "../assets/images/resources/postImg2-3.png";
import PostImg4 from "../assets/images/resources/postImg2-4.png";
import PostImg5 from "../assets/images/resources/postImg2-5.png";
import PostImg6 from "../assets/images/resources/postImg2-6.png";
import PostImg7 from "../assets/images/resources/postImg2-7.png";
import PostImg8 from "../assets/images/resources/postImg2-8.jpg";
import PostImg9 from "../assets/images/resources/PostImg9.jpg";
import PostImg10 from "../assets/images/resources/PostImg10.jpg";
import PostImg11 from "../assets/images/resources/PostImg11.jpg";
import PostImg12 from "../assets/images/resources/PostImg12.jpg";
import PostImg13 from "../assets/images/resources/PostImg14.jpg";
import PostImg15 from "../assets/images/resources/PostImg15.jpg";

export const posts2 = [
  {
    id: 8,
    postImg: PostImg8,
    postDate: "26",
    postMonth: "November",
    postDateLink: "#",
    postTitle: "A gathering of stars in the United States",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment-news/2024/11/26/1054184",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 9,
    postImg: PostImg9,
    postDate: "28",
    postMonth: "October",
    postDateLink: "#",
    postTitle: "Musician Reshmi in a new identity",
    postLink: "#",
    postAuthor: "Rupali Bangladesh",
    postAuthorLink: "https://www.rupalibangladesh.com/entertainment/20783",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 10,
    postImg: PostImg10,
    postDate: "28",
    postMonth: "October",
    postDateLink: "#",
    postTitle: "Musician Reshmi Mirza in a new identity",
    postLink: "#",
    postAuthor: "Protidiner Sangbad",
    postAuthorLink:
      "https://www.protidinersangbad.com/todays-newspaper/entertainment/482347",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 11,
    postImg: PostImg11,
    postDate: "27",
    postMonth: "October",
    postDateLink: "#",
    postTitle: "Musician Reshmi Mirza in a new identity",
    postLink: "#",
    postAuthor: "Sara Bangla",
    postAuthorLink: "https://sarabangla.net/entertainment/post-920485/",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 12,
    postImg: PostImg12,
    postDate: "27",
    postMonth: "October",
    postDateLink: "#",
    postTitle:
      "Vocalist Reshmi joined the International Human Rights Organization",
    postLink: "#",
    postAuthor: "Dainikamader Shomoy",
    postAuthorLink: "https://www.dainikamadershomoy.com/details/0192cd270c43",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 13,
    postImg: PostImg13,
    postDate: "07",
    postMonth: "November",
    postDateLink: "#",
    postTitle:
      "New York State Assembly Celebrates Folk Singer Resmi Mirza’S Global Cultural Impact",
    postLink: "#",
    postAuthor: "Bangla Bulletin",
    postAuthorLink:
      "https://banglabulletin.org/new-york-state-assembly-celebrates-folk-singer-resmi-mirzas-global-cultural-impact/?amp=1",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 14,
    postImg: PostImg15,
    postDate: "04",
    postMonth: "July",
    postDateLink: "#",
    postTitle:
      "People cry when they hear my music, it's an absolute achievement",
    postLink: "#",
    postAuthor: "Kaler Kantho",
    postAuthorLink:
      "https://www.kalerkantho.com/print-edition/rangberang/2024/07/04/1402994",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 1,
    postImg: PostImg1,
    postDate: "07",
    postMonth: "February",
    postDateLink: "#",
    postTitle: "Vocalist Reshmi Mirza in Premer Laddu",
    postLink: "#",
    postAuthor: "Ittefaq",
    postAuthorLink:
      "https://www.ittefaq.com.bd/amp/489295/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A7%87%E0%A6%AE%E0%A7%87%E0%A6%B0-%E0%A6%B2%E0%A6%BE%E0%A6%A1%E0%A7%8D%E0%A6%A1%E0%A7%81%E0%A6%A4%E0%A7%87-%E0%A6%A4%E0%A6%AE%E0%A6%BE-%E0%A6%AE%E0%A6%BF%E0%A6%B0%E0%A7%8D%E0%A6%9C%E0%A6%BE",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 2,
    postImg: PostImg2,
    postDate: "30",
    postMonth: "August ",
    postDateLink: "#",
    postTitle: "Became a model organized by Fried",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink: "https://www.bd-pratidin.com/friday/2019/08/30/452466",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "09",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Reshmir's surprise",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment-news/2019/06/09/430034",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "6",
    postMonth: "February",
    postDateLink: "#",
    postTitle: "Two Mirzas brought 'Premer Laddu'",
    postLink: "#",
    postAuthor: "Dhaka Post",
    postAuthorLink: "https://www.dhakapost.com/entertainment/95967",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "12",
    postMonth: "August",
    postDateLink: "#",
    postTitle: "Shahid-Reshmi's 'Gan Alapan'",
    postLink: "#",
    postAuthor: "Dhaka Post",
    postAuthorLink: "https://www.dhakapost.com/entertainment/53499",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 7,
    postImg: PostImg7,
    postDate: "17",
    postMonth: "July",
    postDateLink: "#",
    postTitle: "Reshmi Mirza in movie song",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment-news/2024/07/17/1011017",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "4",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Reshmi Mirza at Folk Live",
    postLink: "#",
    postAuthor: "Bangladesh Pratidin",
    postAuthorLink:
      "https://www.bd-pratidin.com/entertainment/2021/06/03/655633",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
];
