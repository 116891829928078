import AD1 from "../../assets/Award/award5.jpg";
import AD10 from "../../assets/Award/award14.jpg";
import AD11 from "../../assets/Award/award15.jpg";
import AD12 from "../../assets/Award/award16.jpg";
import AD13 from "../../assets/Award/Ag1.jpg";
import AD14 from "../../assets/Award/Ag2.jpg";
import AD15 from "../../assets/Award/Ag3.jpg";
import AD16 from "../../assets/Award/Ag4.jpg";
import AD17 from "../../assets/Award/Ag5.jpg";
import AD18 from "../../assets/Award/Ag6.jpg";
import AD19 from "../../assets/Award/Ag7.jpg";
import AD20 from "../../assets/Award/Ag8.jpg";
import AD21 from "../../assets/Award/Ag9.jpg";
import AD2 from "../../assets/Award/award6.jpg";
import AD4 from "../../assets/Award/award8.jpg";
import AD5 from "../../assets/Award/award9.jpg";
import AD6 from "../../assets/Award/award10.jpg";
import AD7 from "../../assets/Award/award11.jpg";
import AD8 from "../../assets/Award/award12.jpg";
import AD9 from "../../assets/Award/award13.jpg";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactUs2 } from "../../components/ContactUs";
import { Gellary2 } from "../PortfolioPages/Gellary2";
import { Header2 } from "../../components/Header";
import { Helmet } from "react-helmet";
import { HomeCarousel } from "../../components/AboutUs/HomeCarousel";
import { PageTitle } from "../../components/PageTitle";
import React from "react";

const MyAwards = () => {
  const sliders = [
    AD13,
    AD14,
    AD15,
    AD16,
    AD17,
    AD18,
    AD19,
    AD20,
    AD21,
    AD1,
    AD2,
    AD4,
    AD5,
    AD6,
    AD7,
    AD8,
    AD9,
    AD10,
    AD11,
    AD12,
  ];
  return (
    <React.Fragment>
      <Helmet>
        <title>Award - Resmi Mirza</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Award"
        hometitle="Home"
        homeURL="/"
        currentPage="Award"
      ></PageTitle>
      <section className="workExpWrap relative w-full">
        <div className="fixedBg bg-left-top bg-no-repeat opacity-20"></div>
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto"></div>
      </section>
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto my-12 md:my-12">
        <HomeCarousel></HomeCarousel>
      </div>
      <div className="mx-auto grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-2 lg:grid-cols-4 md:w-[70%] w-[90%] my-4 md:my-8">
        {sliders.map((data, inx) => (
          <div key={inx}>
            <Gellary2 data={data}></Gellary2>
          </div>
        ))}
      </div>
      <ContactUs2></ContactUs2>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default MyAwards;
