import FreelanceWorkMockup from "../../assets/images/resources/IMG.jpg";
import PatternImg2 from "../../assets/images/patternImg2.jpg";

const FreelanceWork = () => {
  return (
    <section className="freelanceWorkWrap w-full relative z-[1]">
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <div className="freelanceWork p-[1.875rem] sm:p-[2.5rem] md:pt-[4.0625rem] md:pb-[3.4375rem] md:pl-[3.75rem] xl:pl-[10.625rem] relative w-full">
          <div
            className="before:absolute before:inset-0 before:bg-accent before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-l-[3.125rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-l-[3.125rem] inset-0 w-full md:w-screen bg-no-repeat bg-center bg-cover z-[-1] bg-accent"
            style={{ backgroundImage: `url(${PatternImg2})` }}
          ></div>
          <div className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1 text-center md:text-left items-center">
            <div className="md:order-1">
              <div className="freelanceImg w-full">
                <img
                  src={FreelanceWorkMockup}
                  alt="Freelance Work Mockup"
                ></img>
                {/* Freelance Image */}
              </div>
            </div>
            <div>
              <div className="freelanceInfo w-full">
                <p className="text-white sm:text-[1.125rem] md:text-[1.125rem] lg:text-[1.25rem] mt-3 flex text-justify">
                  Awards and Recognition on my crafting of passion:
                  <br></br>
                  01. Awarded by the district education officer as the best
                  artist in the folk music competition of the ninth class of
                  Bongobashi secondary school on the occasion of education week
                  2003 from the National Institute of Education 2003 Khulna
                  metropolitan area. He was accepted as the best Jarigan (Folk)
                  song artist in the same program.
                  <br></br>
                  02. Acceptance of Best Singer (Artist) Certificate by National
                  Arts Academy in the tenure 23/6/2013
                  <br></br>
                  03. Appreciation letter received from American Center (Known
                  as American cultural Affairs), The US Embassy Dhaka,
                  Bangladesh
                  <br></br>
                  04. Best Performer Award by Jasim Enterprise Award 2017.
                  <br></br>
                  05. Best Musician Award from 20th Television Reporters
                  Association of Bangladesh (TRAB) Award at Music Artist
                  category in 2020
                  <br></br>
                  06. Dhaliwood Music Award 2023 for Best Folk Artist, Held in
                  New York, USA.
                  <br></br>
                  07. Certificate of Recognition from District of Governance
                  City of License, Los Angeles, USA.
                  <br></br>
                  08. Lalon Mela New York, USA 2023 by Michael M. Berger Mayor
                  of the City of Paris Los Angeles.
                  <br></br>
                  09. Appreciation Letter from Global Television from Channel 9,
                  Ekushey TV, from RTV, Nagarik Television from NTV and Gaan
                  Bangla Television.
                </p>
                {/* Freelance Info */}
              </div>
            </div>
          </div>
          {/* Freelance Work */}
        </div>
      </div>
      {/* Freelance Work Wrap */}
    </section>
  );
};

export default FreelanceWork;
